import React, { Component } from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'

import Title from '../components/page-title'
//import SubTitle from "../components/page-subtitle";
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
//import PageVideo from '../components/page-video';
import PageButtons from '../components/page-buttons'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'
import PageCtaFeature from '../components/page-cta-feature'
import PageVideo from '../components/page-video'
import PageOverview from '../components/page-overview'
import PageHeroGallery from '../components/page-hero-gallery'
import PageRuledList from '../components/page-ruled-list'

import Image1 from '../images/etc/happy-haus-wollumbin-11.jpg'
import Image2 from '../images/etc/happy-haus-helensvale-10.jpg'
import Image3 from '../images/etc/happy-haus-helensvale-13.jpg'
import Image4 from '../images/resources/happyhaus-gallery-2019-07-24-photo-strong-knockdown-1607.jpg'
const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
]

const DemoVideo = 'https://youtu.be/twzTW78Zza0'
const mapDispatchToProps = dispatch => ({})

const Guide2Image = 'resources/happyhaus-guide-knockdown-cover.png'

class Page extends Component {
  constructor(props) {
    super(props)
    this._handleRequestClick = this._handleRequestClick.bind(this)
  }

  _handleRequestClick(e, form) {
    e.preventDefault()
    const { dispatch } = this.props

    switch (form) {
      case 'knockdown':
        dispatch(
          setLightboxForm({
            category: 'Build Guides Knockdown',
            title: 'Get your Knockdown Rebuild Guide',
            redirect: '/guide-knockdown-rebuild-thank-you',
          })
        )
        break

      case 'new':
        dispatch(
          setLightboxForm({
            category: 'Build Guide New',
            title: 'Get your free guide<br />to building your new home',
            redirect: '/build-guides-thank-you',
          })
        )
        break

      default:
        break
    }
  }

  render() {
    const CtaFeatures = [
      {
        title: '&nbsp;',
        image: Guide2Image,
        buttonLabel: 'Download our guide',
        bgColor: '#f1f1f1',
        bgColorHover: '#eeeeee',
        onClick: e => {
          this._handleRequestClick(e, 'knockdown')
        },
      },
    ]

    return (
      <>
        <SEO
          title="Knockdown Rebuild Guide"
          description="Considering a knockdown rebuild is a common situation for many
                of our clients. In some cases they love the location of their
                current home but it's just not big enough for their growing
                family, or, they want to experience living in a brand new home,
                designed just for them."
          images={ogImages}
        />
        <Row>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 6 / 12]} px={2}>
              <Title>Considering a knockdown rebuild?</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2}>
              <PageRuledList>
                <li>
                  <strong>Our guide has expert tips to help you</strong>
                </li>
                <li>
                  Understand your block to maximise your options and budget
                </li>
                <li>
                  Plan for success with your vision, budget and chosen build
                  team
                </li>
                <li>Know what to consider when selecting a design</li>
                <li>
                  Be prepared for the pre and post demolition stage and more
                </li>
              </PageRuledList>
            </Box>
          </Flex>
        </Row>
        <PageCtaFeature features={CtaFeatures} />
        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
              <Title>
                Looking for the perfect location <em>and</em> home?
              </Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <Copy align="left">
                <p>
                  Our knockdown rebuild clients usually fall into one of two
                  categories. In some cases clients love the location of their
                  current home, but it’s just not big enough for their growing
                  family, or they want to experience living in a brand new home,
                  designed just for them. Other clients have purchased an older
                  property that needs "some" work and are trying to decide
                  whether to renovate or start a-fresh!
                </p>

                <p>
                  Although it may sound like another drama to deal with,
                  knocking down an existing home and building new can actually
                  be a lot more simple than a renovation project. We’re experts
                  in knockdown and rebuild projects, contact us today if you’d
                  like more information.
                </p>
                <p>
                  <a
                    onClick={e => {
                      e.preventDefault()
                      this._handleRequestClick(e, 'knockdown')
                    }}
                  >
                    <strong>Download our FREE guide</strong>
                  </a>
                </p>
              </Copy>{' '}
            </Box>
          </Flex>
        </Row>{' '}
        <PageVideo src={DemoVideo} />
      </>
    )
  }
}

export default connect(mapDispatchToProps)(Page)
